import React, { Fragment } from "react"
import RentwareArticleBooking from "./rentwareArticleBooking";
import {Link} from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'

const RentwareArticlesGrid = (props) => {
    return (
        <div className="md:grid-cols-2 xl:grid-cols-3 grid p-4 mb-12 lg:max-w-[1440px] mx-auto gap-16 md:gap-32 items-center lg:px-8 xl:px-10">
            {props.articles.map(article => (
                <Fragment
                    key={article.articleId}
                >
                    <div className="grid items-center gap-4 lg:grid-cols-2 md:gap-8 xl:col-span-2">
                        <div className="max-w-xs mx-auto lg:max-w-md">
                            <GatsbyImage image={article.image} />
                        </div>
                        <div className="max-w-xs mx-auto mb-6 md:max-w-prose">
                            <h1 className="mb-2 text-lg md:text-2xl"><Link to={article.href}>{article.h1}</Link></h1>
                            <h2 className="mb-4 text-sm md:text-lg">{article.h2}</h2>
                            <p className="text-xs md:text-sm leading-[1.4em]">{article.text}</p>
                        </div>
                    </div>

                    <RentwareArticleBooking articleId={article.articleId} view={article.view} />
                </Fragment>
            ))}
        </div>
    )
}

export default RentwareArticlesGrid